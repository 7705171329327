<template>
  <div class="extration-bdd-frais">
    <b-button
      v-if="checkPermission('GGDFTHGFA') && computedCheckedRowsAvoir.length"
      size="sm"
      class="button-export-style  ml-2"
      @click="handelShowModal"
      title="Générer les avoirs"
    >
      <font-awesome-icon icon="check-double"
    /></b-button>
    <b-modal
      ref="date-avoir-global"
      id="date-avoir-global"
      no-close-on-backdrop
      :hide-footer="true"
      :title="
        'Êtes-vous sur de generes les avoir(s) pour les facture(s) suivantes'
      "
      @hidden="hideModal('date-avoir-global')"
      modal-class="modal-validation-statut-AV"
    >
      <b-form-group
        v-if="checkPermission('SFTHDCA')"
        label-cols-sm="5"
        label-cols-lg="4"
        content-cols-sm
        content-cols-lg="7"
        label="Date de l'avoir : "
      >
        <date-picker
          value-type="format"
          format="YYYY-MM-DD"
          type="date"
          :value="date"
          class="inputDateTemplate"
          @input="handleDateTimePicker"
        ></date-picker>
      </b-form-group>
      <b-form-group
        label-cols-sm="5"
        label-cols-lg="4"
        content-cols-sm
        content-cols-lg="7"
        label="Liste des factures : "
      >
      </b-form-group>
      <li v-for="av in tableToUse" :key="'av' + av.num">
        {{ av.num }}
      </li>

      <div class="form-modal-custom-style mt-1 mb-1">
        <div class="messageError text-center">
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button class="button-valide-style " @click="handelGenerateAvoir">
            <span>
              Générer
              <div v-if="loading" class="loading ml-2">
                <div class="spinner-border" role="status"></div></div
            ></span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  props: {
    dataToUse: { required: true },
    interfaceName: { required: true }
  },
  data() {
    return {
      error: null,
      loading: false,
      tableToUse: [],
      date: null
    };
  },
  methods: {
    ...mapActions(['avoirFraisDynamicTh']),
    handleDateTimePicker(data) {
      this.date = data;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.date = null;
      this.error = null;
      this.loading = false;
      this.tableToUse = [];
    },
    // showConfirmAlert() {
    //   let table = this.computedCheckedRowsAvoir;
    //   let htmlMessage =
    //     "<div  style='margin: auto;width: 50%;height: 250px;overflow-x: auto;' class='ModalScrol'><div> ";
    //   this.tableToUse = [];
    //   for (let i = 0; i < table.length; i++) {
    //     for (let j = 0; j < table[i].projects.length; j++) {
    //       if (
    //         table[i].projects[j] &&
    //         table[i].projects[j].check == true &&
    //         table[i].projects[j].etat != 'Annulée' &&
    //         table[i].projects[j].type_facture != 'Facture proforma' &&
    //         table[i].projects[j].modifiable == true
    //       ) {
    //         this.tableToUse.push(table[i].projects[j]);
    //         htmlMessage =
    //           htmlMessage + '<li>' + table[i].projects[j].num + '</li>';
    //       }
    //     }
    //     htmlMessage = htmlMessage + '</div></div>';
    //     this.$swal
    //       .fire({
    //         background: 'rgb(245 245 252)',
    //         title:
    //           'Êtes-vous sur de generes les avoir(s) pour les facture(s) suivantes',
    //         type: 'warning',
    //         icon: 'warning',
    //         locale: 'fr',
    //         showLoaderOnConfirm: true,
    //         allowOutsideClick: false,
    //         showCloseButton: true,
    //         showConfirmButton: true,
    //         showCancelButton: true,
    //         html: htmlMessage,
    //         confirmButtonText: 'Oui',
    //         cancelButtonText: 'Non',
    //         confirmButtonClass: 'btn btn-success',
    //         cancelButtonClass: 'btn btn-danger',
    //         cancelButtonColor: '#d33',
    //         customClass: {
    //           actions: 'my-actions',
    //           cancelButton: 'order-2 ',
    //           confirmButton: 'order-1'
    //         },
    //         preConfirm: () => {
    //           this.$swal.showLoading();
    //         }
    //       })
    //       .then(async result => {
    //         if (result.isConfirmed) {
    //           var bodyFormData = new FormData();
    //           for (let i = 0; i < this.tableToUse.length; i++) {
    //             bodyFormData.append('ids[' + [i] + ']', this.tableToUse[i].id);
    //           }
    //           const response = await this.avoirFraisDynamicTh({
    //             ids: bodyFormData,
    //             data: this.tableToUse
    //           });
    //           if (response) {
    //             this.$alert('', 'Avoir creé avec succès', 'success');
    //           }
    //         }
    //       });
    //   }
    // },
    async handelGenerateAvoir() {
      this.loading = true;
      var bodyFormData = new FormData();
      bodyFormData.append('date', this.date);

      for (let i = 0; i < this.tableToUse.length; i++) {
        bodyFormData.append('ids[' + [i] + ']', this.tableToUse[i].id);
      }
      const response = await this.avoirFraisDynamicTh({
        ids: bodyFormData,
        data: this.tableToUse
      });
      if (response) {
        this.hideModal('date-avoir-global');
        this.$alert('', 'Avoir creé avec succès', 'success');
      }
      this.loading = false;
    },
    handelShowModal() {
      let table = this.computedCheckedRowsAvoir;
      this.tableToUse = [];
      for (let i = 0; i < table.length; i++) {
        for (let j = 0; j < table[i].projects.length; j++) {
          if (
            table[i].projects[j] &&
            table[i].projects[j].check == true &&
            table[i].projects[j].etat != 'Annulée' &&
            table[i].projects[j].type_facture != 'Facture proforma' &&
            table[i].projects[j].modifiable == true
          ) {
            this.tableToUse.push(table[i].projects[j]);
          }
        }
      }
      let now = new Date();
      this.date = moment(now).format('YYYY-MM-DD');
      this.$refs['date-avoir-global'].show();
    }
  },
  computed: {
    ...mapGetters(['checkPermission']),
    computedCheckedRowsAvoir() {
      return this.dataToUse?.filter(k => {
        return k.projects.some(
          e =>
            e.check == true &&
            e.etat != 'Annulée' &&
            e.type_facture != 'Facture proforma' &&
            e.modifiable == true
        );
      });
    }
  }
};
</script>

<style scoped lang="scss">
.button-export-style {
  background-color: #e4261b;
  &:hover,
  &:focus {
    background-color: #e4261b;
  }
}
</style>
<style lang="scss">
.align-item-validate-statut-installateur-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-validation-statut-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
.ModalScrol::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.ModalScrol::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.ModalScrol::-webkit-scrollbar-thumb {
  background: #b5b5e6;
  border-radius: 7px;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
</style>
